<template>
<secondary-page-layout :title="$t('feedback')">
  <ValidationObserver ref="form" v-slot="{}">
    <div class="form-group py-3">
      <div class="mb-4">
        <p class="text-center mb-4">
          {{$t('pleaseRateService')}}
          </p>
        <rating-stars @input="val => form.rating = val" />
      </div>
      <div class="feedback">
        <p class="text-center mb-4">{{$t('comments')}}</p>
        <textarea class="textarea" :placeholder="$t('write')" v-model="form.comment"></textarea>
      </div>
      <button class="primary-btn bottom-page-btn" @click="submit" :disabled="isButtonDisabled">{{$t('leaveFeedback')}}</button>
    </div>
  </ValidationObserver>
</secondary-page-layout>
</template>

<script>
import RatingStars from "../components/RatingStars";
import FeedbackResultModal from "../components/Modals/FeedbackResultModal";
import SosResultModal from "../components/Modals/SosResultModal";
import eventLogger from '../services/eventLogger';
export default {
  name: "ChatFeedback",
  // eslint-disable-next-line vue/no-unused-components
  components: {RatingStars, FeedbackResultModal},
  data() {
    return {
      form: {
        rating: null,
        comment: null,
        product_id: null
      },
      isButtonDisabled: true
    }
  },
  methods: {
    getRating(val) {
      this.form.rating = val;
    },
    redirect() {
      this.$router.push('/chat-navigation');
    },
    async submit() {
      try {
        let success = await this.$refs.form.validate()
        if (success) {
          this.$modal.show(FeedbackResultModal, {rating: this.form.rating}, this.$modalConfig);
          await window.axios.post('/feedback', this.form);
          setTimeout(this.redirect, 400)
        }
        eventLogger('feedback', {feedback_text: this.form.comment, rating: this.form.rating})
      } catch(e) {
        throw e
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        if(val.rating) {
          this.isButtonDisabled = false;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback {
.textarea {
  height: 120px;
  margin-bottom: 24px;
}
}
</style>
